import React, { useEffect, useState } from 'react';
import Button from '../Button';
import './index.scss';

export type StickyButtonsProps = {
  isDisabled?: boolean;
  proceedButton?: { label: string; onClick: () => void };
  isLoading?: boolean;
  hasError?: boolean;
  cancelButton: { label: string; onCancel: () => void };
};

const StickyButtons = ({
  isDisabled,
  proceedButton,
  isLoading,
  cancelButton,
  hasError,
}: StickyButtonsProps) => {
  const intersectionELementRef = React.useRef(null);
  const [isSticky, setIsSticky] = useState(true);

  const observerCallback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsSticky(!entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback);
    if (intersectionELementRef?.current) {
      observer.observe(intersectionELementRef.current);
    }
    return () => {
      if (intersectionELementRef?.current) {
        observer.unobserve(intersectionELementRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className={`sticky-buttons ${isSticky ? 'white-bg' : ''}`}>
        {proceedButton ? (
          <Button
            isDisabled={isDisabled}
            onClick={proceedButton.onClick}
            label={proceedButton.label}
            loading={isLoading}
          />
        ) : null}
        {hasError && <div className='form-error'>Bitte geben Sie die fehlenden Daten ein</div>}
        <div onClick={cancelButton.onCancel} className='cancel-button'>
          {cancelButton.label}
        </div>
      </div>
      <div ref={intersectionELementRef} />
    </>
  );
};

export default StickyButtons;
