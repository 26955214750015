enum TooltipStateEnum {
  NOTIFICATION_HEADER = 'NOTIFICATION_HEADER',
  NOTIFICATION_ACCOUNT = 'NOTIFICATION_ACCOUNT',
  NOTIFICATION_PRIVATE = 'NOTIFICATION_PRIVATE',
  NOTIFICATION_PARTNERS = 'NOTIFICATION_PARTNERS',
  NOTIFICATION_PAYMENT_DETAILS = 'NOTIFICATION_PAYMENT_DETAILS',
  NOTIFICATION_DETAILS_HEADER = 'NOTIFICATION_DETAILS_HEADER',
  NOTIFICATION_DETAILS_REFERENCE_NUMBER = 'NOTIFICATION_DETAILS_REFERENCE_NUMBER',
  NOTIFICATION_DETAILS_ADDRESS = 'NOTIFICATION_DETAILS_ADDRESS',
  NOTIFICATION_DETAILS_CUSTOMER = 'NOTIFICATION_DETAILS_CUSTOMER',
  ACCOUNT_CLOSING_HEADER = 'ACCOUNT_CLOSING_HEADER',
  ACCOUNT_CLOSING_FORM = 'ACCOUNT_CLOSING_FORM',
  STANDING_ORDER = 'STANDING_ORDER',
  STANDING_ORDER_DETAILS = 'STANDING_ORDER_DETAILS',
  DAC_HEADER = 'DAC_HEADER',
  MANUAL_KWS = 'MANUAL_KWS',
  DIGITAL_SIGNATURE = 'DIGITAL_SIGNATURE',
  SWITCHING_DATE = 'SWITCHING_DATE',
  SWITCHING_DATE_HEADER = 'SWITCHING_DATE_HEADER',
  WELCOME_BANNER = 'WELCOME_BANNER',
  ALERT_SECTION = 'ALERT_SECTION',
  MISSING_IBAN = 'MISSING_IBAN',
  GUARANTEE_SECTION = 'GUARANTEE_SECTION',
  REVIEW_SECTION = 'REVIEW_SECTION',
  SUCCESS_SECTION = 'SUCCESS_SECTION',
  TIMELINE_SECTION = 'TIMELINE_SECTION',
  TOGGLE_SECTION = 'TOGGLE_SECTION',
}

interface ITooltipStateProvider {
  tooltipState?: TooltipStateEnum;
  setTooltipState: (val: TooltipStateEnum | undefined) => void;
}

export { TooltipStateEnum, ITooltipStateProvider };
