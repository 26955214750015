import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import './index.scss';
import ServiceGateway from '../../../../../services/ServiceGateway';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import { isDesktopOrTablet } from '../../../../../utils/helper';
import { isProd } from '../../../../../utils/environment';

export interface FinApiContainerProps {
  finApiToken: string;
  onSuccess: (processingTime: number) => void;
  renewFinApiProcess: () => void;
}

declare const FinApiWebForm: any;

const FinApiContainer = ({ finApiToken, onSuccess, renewFinApiProcess }: FinApiContainerProps) => {
  const { kwsState } = useKwsState();

  const createWebForm = () => {
    const finApiContainer = document.querySelector('#webFormContainer');

    const handleComplete = async () => {
      const fetchDacResult = await ServiceGateway.fetchFinApiDac(kwsState!.id);
      onSuccess(fetchDacResult.data.processingTime);
    };
    const handleFail = () => {
      renewFinApiProcess();
    };
    const handleAbort = () => {
      renewFinApiProcess();
    };
    const handleLoadError = () => {
      Sentry.captureMessage('FinApiWebForm load error');
    };

    FinApiWebForm?.load(
      finApiContainer,
      {
        token: finApiToken,
        language: 'de',
        targetEnvironment: isProd() ? 'live' : 'sandbox',
        layoutConfig: isDesktopOrTablet() ? 'sm' : 'xs',
        noMargins: 'true',
      },
      {
        onComplete: handleComplete,
        onFail: handleFail,
        onAbort: handleAbort,
        onLoadError: handleLoadError,
      },
      document,
    );
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://webform-dist.finapi.io/latest/dist/web-form.min.js';
    script.addEventListener('load', () => {
      if (finApiToken) {
        createWebForm();
      }
    });
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className='finapi-container'>
      <div id='webFormContainer' />
    </div>
  );
};

export default FinApiContainer;
