import React, { useEffect, useState } from 'react';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import Intro from '../../CommonSections/Intro';
import { scrollToPosition } from '../../../../utils/helper';
import { NotificationDetailsModel } from '../../../../types/Overview/NotificationModel';
import SpinnerFullPage from '../../../Common/SpinnerFullPage';
import { ValidationErrorNotification } from '../../../../utils/validators';
import ServiceGateway from '../../../../services/ServiceGateway';
import TabButtons from '../../../Common/TabButtons';
import NotificationDetailTab from './components/NotificationDetailTab';
import AllTransactionsTab from '../../CommonSections/AllTransactionsTab';
import { ItemType } from '../../../../types/Overview/Common';
import ResetButton from '../../../Common/ResetButton';

const NotificationDetails = () => {
  const { kwsState } = useKwsState();
  const { notificationToProcess, setNotificationToProcess, setSwitchingDateToProcess } =
    useKwsState();
  const { pageType, id: notificationId } = notificationToProcess!;
  const [validationError, setValidationError] = useState<ValidationErrorNotification>({});
  const [notificationDetailState, setNotificationDetailState] =
    useState<NotificationDetailsModel>();

  const getNotification = async () => {
    switch (pageType) {
      case 'EDIT': {
        let { data: notificationResponse } = await ServiceGateway.getNotification(
          kwsState!.id,
          notificationId!,
        );

        let nameErrorCheck = false;
        let streetErrorCheck = false;
        let streetNumErrorCheck = false;
        let cityErrorCheck = false;
        let postalCodeErrorCheck = false;

        if (!notificationResponse.recipient?.name) {
          if (notificationResponse.normalizedCounterName) {
            notificationResponse = {
              ...notificationResponse,
              recipient: {
                ...notificationResponse.recipient,
                name: notificationResponse.normalizedCounterName,
              },
            };
          } else {
            nameErrorCheck = true;
          }
        }
        if (notificationResponse?.recipient?.address?.germanAddress !== false) {
          if (notificationResponse.private && !notificationResponse.recipient?.address?.street) {
            streetErrorCheck = true;
          }
          if (notificationResponse.private && !notificationResponse.recipient?.address?.streetNum) {
            streetNumErrorCheck = true;
          }
          if (!notificationResponse.recipient?.address?.city) {
            cityErrorCheck = true;
          }
          if (!notificationResponse.recipient?.address?.postalCode) {
            postalCodeErrorCheck = true;
          }
        }

        const unvalidObject = {
          valid: false,
          message: 'Bitte geben Sie die fehlenden Informationen ein',
        };
        const validObject = {
          valid: true,
        };

        setValidationError((validations) => ({
          ...validations,
          FIELD: {
            ...validations?.FIELD,
            'recipient.name': nameErrorCheck ? unvalidObject : validObject,
            'recipient.address.street': streetErrorCheck ? unvalidObject : validObject,
            'recipient.address.streetNum': streetNumErrorCheck ? unvalidObject : validObject,
            'recipient.address.city': cityErrorCheck ? unvalidObject : validObject,
            'recipient.address.postalCode': postalCodeErrorCheck ? unvalidObject : validObject,
          },
        }));

        setNotificationDetailState(notificationResponse);
        break;
      }
      case 'ADD': {
        const response = await ServiceGateway.preCreateNotification(kwsState!.id);
        setNotificationDetailState(response.data);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    scrollToPosition('0');
    getNotification();
  }, []);

  const goBack = () => {
    setNotificationToProcess(undefined);
    setSwitchingDateToProcess(false);
  };

  if (!notificationDetailState) {
    return <SpinnerFullPage />;
  }

  const tabData = [
    {
      id: 0,
      title: 'Mitteilung',
      content: (
        <NotificationDetailTab
          initialValidationError={validationError}
          notificationDetailState={notificationDetailState}
          setNotificationDetailState={setNotificationDetailState}
        />
      ),
    },
    ...(notificationDetailState.transactions && notificationDetailState.transactions.length !== 0
      ? [
          {
            id: 1,
            title: 'Transaktionen',
            content: (
              <AllTransactionsTab
                transactions={notificationDetailState!.transactions!}
                pageType={ItemType.NOTIFICATION}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <div className='notification-details' data-testid='notification-details'>
      <ResetButton title='zurück zur Übersicht' onClick={goBack} />

      {pageType === 'EDIT' && (
        <Intro
          data={notificationDetailState}
          pageType='notification'
          classNames={tabData.length > 1 ? 'bottom-border' : ''}
        />
      )}

      {tabData.length > 1 ? <TabButtons tabs={tabData} classNames='sticky' /> : tabData[0].content}
    </div>
  );
};

export default NotificationDetails;
