import React, { useEffect, useState } from 'react';
import './index.scss';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import ServiceGateway from '../../../../services/ServiceGateway';
import ContactCard from '../../../Common/ContactCard';
import DateInput from '../../../Common/DateInput';
import { ValidationResult } from '../../../../utils/validators';
import { useOverview } from '../../../../contexts/OverviewContext/OverviewContext';
import Tooltip from '../../../Common/Tooltip';
import StickyButtons from '../../../Common/StickyButtons';
import InfoBox from '../../../Common/InfoBox';
import { TooltipStateEnum } from '../../../../contexts/TooltipStateContext/Tooltip.types';
import WelcomeSection from './sections/WelcomeSection';
import { scrollToPosition } from '../../../../utils/helper';
import ResetButton from '../../../Common/ResetButton';

const CloseAccountDetails = () => {
  const { kwsState, setAccountToProcess, setSwitchingDateToProcess } = useKwsState();

  const { overviewData } = useOverview();
  const { closingData } = overviewData!;
  const [accountClosingDate, setAccountClosingDate] = useState(closingData.accountClosingDate);

  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState<Record<string, ValidationResult>>({});

  const errorCheck = !Object.keys(validationError).every(
    (key) => validationError[key].valid ?? false,
  );

  const submit = async () => {
    setLoading(true);
    try {
      const account = {
        accountClosingDate,
        state: closingData.state,
        isNewBalanceDestinationAccount: false,
      };
      const result = await ServiceGateway.closingData(kwsState!.id, account);
      const isSuccess = result.status >= 200 && result.status < 300;
      if (isSuccess) {
        setAccountToProcess(false);
        setSwitchingDateToProcess(false);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce(
              (acc: any, err: any) => ({
                ...acc,
                [err.key]: { valid: false, message: err.message },
              }),
              {},
            ),
          });
        }
      }
    }
    setLoading(false);
  };

  const goBack = () => {
    setAccountToProcess(false);
    setSwitchingDateToProcess(false);
  };

  useEffect(() => {
    scrollToPosition('0');
  }, []);

  return (
    <div className='close-account' data-testid='close-account'>
      <ResetButton title='zurück zur Übersicht' onClick={goBack} />

      <WelcomeSection oldBank={closingData.oldBank} />

      {/* Form */}
      <div className='form-container'>
        <div className='description'>
          Sie können individuell wählen, wann Ihr bisheriges Konto geschlossen und der Restsaldo mit
          Ihrem neuen Konto verrechnet werden soll.
        </div>

        {/* New Account */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Schließung des bisherigen Kontos</div>
            <Tooltip tooltipSectionName={TooltipStateEnum.ACCOUNT_CLOSING_FORM} hasBackground>
              <p>
                Negative Salden können nur dann verrechnet werden, wenn auf dem neuen Konto ein
                Dispokredit in entsprechender Höhe eingerichtet wurde. Der Kontoumzug kann einen
                bestehenden Dispokredit nicht automatisch auf das neue Konto übertragen. Ohne
                Dispokredit muss ein negativer Saldo vor der Kontoschließung ausgeglichen werden.
              </p>
            </Tooltip>
          </div>
          <div className='form-input'>
            <DateInput
              value={accountClosingDate}
              onChange={(v) => setAccountClosingDate(v)}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  'process.closingData': e,
                }))
              }
              validation={validationError?.['process.closingData']}
            />
          </div>
          <InfoBox type='info'>
            <p>
              Das frühestmögliche Datum zur Schließung Ihres bisherigen Kontos wurde automatisch
              errechnet. Erfahrungsgemäß benötigen die Zahlungspartner eine gewisse Zeit, um Ihren
              Kontoumzug zu bestätigen. Aus diesem Grund ist eine frühere Schließung des bisherigen
              Kontos nicht auswählbar.
            </p>
          </InfoBox>
        </div>
      </div>

      <StickyButtons
        isDisabled={errorCheck}
        isLoading={loading}
        proceedButton={{ label: 'speichern', onClick: submit }}
        cancelButton={{ label: 'Änderungen verwerfen', onCancel: goBack }}
      />

      <ContactCard />
    </div>
  );
};

export default CloseAccountDetails;
